const NUM_ROWS = 3
const NUM_COLS = 4

const CELL_WIDTH = 300
const CELL_HEIGHT = 533

const CAN_WIDTH = 300
const CAN_HEIGHT = 500

const colors = [
  '#ffc600', // 'Young Mango'
  '#ff8672', // 'Extra Peach'
  '#dcc7b7', // 'Toasted Coconut'
  '#006098', // 'Sour Blueberry'
  '#752e4a', // 'Blackberry Jam'
  '#ddb5c8', // 'White Grape'
  '#ffd720', // 'Lemon Verbena'
  '#93d500', // 'Pear Elderflower'
  '#f4436c', // 'Strawberry Basil'
  '#ff8300', // 'Orange Nectarine'
  '#4a9462', // 'Gingery Ale'
  '#db0032', // 'Cherry Pop'
]

const shuffle = (arr) => {
  let currentIndex = arr.length
  let randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]]
  }

  return arr
}

const getRandom = (min, max) => {
  return Math.random() * (max - min) + min
}

const saveSvg = (svgEl, name) => {
  svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
  const svgData = svgEl.outerHTML
  const preface = '<?xml version="1.0" standalone="no"?>\r\n'
  const svgBlob = new Blob([preface, svgData], { type: 'image/svg+xml;charset=utf-8' })
  const svgUrl = URL.createObjectURL(svgBlob)
  const downloadLink = document.createElement('a')
  downloadLink.href = svgUrl
  downloadLink.download = name
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

const svg = document.getElementById('poster')

const setupCells = () => {
  const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs')

  for (let x = 0; x < NUM_COLS; x++) {
    for (let y = 0; y < NUM_ROWS; y++) {
      const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
      rect.setAttribute('width', CELL_WIDTH)
      rect.setAttribute('height', CELL_HEIGHT)
      rect.setAttribute('x', x * CELL_WIDTH)
      rect.setAttribute('y', y * CELL_HEIGHT)

      const clipPath = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath')
      clipPath.setAttribute('id', `cell-${x}-${y}`)
      clipPath.appendChild(rect)
      defs.appendChild(clipPath)
    }
  }

  svg.appendChild(defs)
}

const setupCans = () => {
  const randomizedColors = shuffle(colors)

  for (let x = 0; x < NUM_COLS; x++) {
    for (let y = 0; y < NUM_ROWS; y++) {
      const canClip = document.createElementNS('http://www.w3.org/2000/svg', 'g')
      canClip.setAttribute('clip-path', `url(#cell-${x}-${y})`)

      const canPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      canPath.setAttribute(
        'd',
        'M259.1 60.4c-17.9-26.5-5.2-35.2-15.7-36.5C150 23 177.7 23 150 23c-27.7.1 0 0-93.3 1C46.3 25.3 59 33.9 41 60.4c-11.1 16.4-16.3 25.7-16 40.8V450c1 8.9 13.9 8.4 22.9 14.5 5.5 3.7 8.1 10.9 16.3 11.9 84.7.7 84.7 1.2 171.5 0 8.2-.9 10.9-8.1 16.3-11.9 9-6.1 21.8-5.6 22.9-14.5V101.3c.4-15.2-4.8-24.4-15.8-40.9z'
      )

      const color = randomizedColors.pop()
      canPath.setAttribute('fill', color)

      const xPos = x * CELL_WIDTH + (CELL_WIDTH - CAN_WIDTH) / 2
      const yPos = y * CELL_HEIGHT + (CELL_HEIGHT - CAN_HEIGHT) / 2

      const randomDegrees = getRandom(0, 359)

      const rotation = `rotate(${randomDegrees} ${xPos + CELL_WIDTH / 2} ${yPos + CELL_WIDTH / 2})`

      const xOffset = getRandom(-150, 150)
      const yOffset = getRandom(-250, 250)

      const translation = `translate(${xPos + xOffset} ${yPos + yOffset})`

      canPath.setAttribute('transform', `${rotation} ${translation}`)

      canClip.appendChild(canPath)

      svg.appendChild(canClip)
    }
  }
}

setupCells()
setupCans()

svg.addEventListener('click', () => saveSvg(svg, `usoa-poster-${Date.now()}.svg`))
